import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';
import '@fontsource/metropolis/400.css';
import '@fontsource/metropolis/700.css';
import './src/styles/global.css';

export const onClientEntry = async (): Promise<void> => {
    if (typeof IntersectionObserver === `undefined`) {
        await import('intersection-observer');
    }
};
